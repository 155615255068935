import styled from "@emotion/styled";

export const SectionTitle = styled.h3`
    font-family: Barlow, sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: center;
    color: #111213;

    margin: auto;
    display: block;
    text-align: center;
`;
