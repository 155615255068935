import * as React from "react";
import styled from "@emotion/styled";
import queryString from "query-string";
import { FeatureImageSection } from "@fitplan/lib/components/FeatureImageSection";
import { SectionTitle } from "@fitplan/lib/components/Header/SectionTitle";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import localize from "../hoc/Localize";
import { TestimonialSection } from "../components/subscribe/TestimonialSection";
import { Subscribe } from "../components/subscribe";

import "./subscribe.scss";
import "@fitplan/lib/components/LandingPage/plan.scss";
import { useConfig } from "@fitplan/context/lib-es/config";

export interface Props {
    location: { search: string };
}
const IceShaker: React.SFC<Props> = props => {
    const { imageBaseUrl } = useConfig();
    return (
        <PageWrapper {...props} className="subscribe cms-plan-view">
            <Subscribe
                title="Train with Gronk in 2020!"
                subtitle="Join Fitplan free for 30 days and get full access to 40+ elite trainers"
                stripeProductId="prod_GDq5miXcpRjs4u"
                mobileImageSrc={`${imageBaseUrl}/web/partners/ice-shaker/gronk.jpg?tr=w-800`}
                stripePlans={[
                    {
                        active: true,
                        amount: 1999,
                        billingScheme: "per_unit",
                        created: 1574376478,
                        currency: "usd",
                        id: "plan_GDq7SzezApIcof",
                        planId: "plan_GDq7SzezApIcof",
                        interval: "month" as const,
                        interval_count: 1,
                        name: "Fitplan Monthly US",
                        trialPeriodDays: 30,
                    },
                    {
                        active: true,
                        amount: 9999,
                        billingScheme: "per_unit",
                        created: 1574376414,
                        currency: "usd",
                        id: "plan_GDq6nJJkC6VFx7",
                        planId: "plan_GDq6nJJkC6VFx7",
                        interval: "year" as const,
                        interval_count: 1,
                        name: "Fitplan Annual US",
                        trialPeriodDays: 30,
                    },
                ]}
            />
            <TestimonalBox>
                <SectionTitle>Testimonials + Transformations</SectionTitle>
                <TestimonialSection />
            </TestimonalBox>
            <div style={{ marginTop: "32px", marginBottom: "16px" }}>
                <SectionTitle>Fitplan: Your Personal Training App</SectionTitle>
            </div>
            <FeatureImageSection />
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

const TestimonalBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 60px;
    padding-bottom: 48px;

    background-color: #f0f0f0;
`;

export default localize(IceShaker);
